import * as React from "react";

import Layout from "../../components/Layout";
import ToolCard from "../../components/cards/ToolCard"
import ContentPageHeader from "../../components/ContentPageHeader"

import ExtensionSearchThumbnail from "../../img/infoGraphics/search.png"
import DependentDropdownThumbnail from "../../img/tools/NEW-dependentDropDowns.png"
import FormsAutoReplyThumbnail from "../../img/tools/NEW-gforms-autoreply.png"
import InFeedAd from "../../components/adUnits/inFeedAd"

export default class ToolsIndexPage extends React.Component {
  render() {
    const tools = [
        { 
          type: "extension",
          title: "NitroGAS Chrome Extension",
          description: "I originally NitroGAS Chrome Extension to help myself build in Google Apps script faster. With improvements to the Apps Script Editor and quick access to helpful code snippets, this tool will help you build your scripts faster than you could imagine. The tool itself and access to the code snippets are 100% FREE - Happy Coding!",
          thumbnail: ExtensionSearchThumbnail,
          pageUrl: "https://chrome.google.com/webstore/detail/bootstrapping-tools-nitro/najbgpipgacnhjcaabbhdaddlmebmopb",
          cta: 'Get the Extension'
        },
        {
          type: "tool",
          title: "Dependent Dropdowns in Google Sheets",
          description: "This tool will help you generate code that'll allow for dropdowns that dynamically populate its options based on a previously selected value. It supports an infinite number of dropdowns and an infinite number of options for each one.",
          thumbnail: DependentDropdownThumbnail,
          pageUrl: "/tools/dependent-drop-downs",
          cta: 'Use the Tool'
        },
        {
          type: "tool",
          title: "Google Forms Auto-reply Emailer",
          description: "Combine the intuitive experience of Google Forms with the power of automation when you use this tool to generate code that sends auto-replies to anyone that submits a response to your form.",
          thumbnail: FormsAutoReplyThumbnail,
          pageUrl: "/tools/forms-autoreply-emailer",
          cta: 'Use the Tool'
        }
    ]
    return (
      <Layout>
        <ContentPageHeader title="Supercharged Tools" />
        <section className="section">
          <div className="container">
            <div className="content">
              {tools && tools.map((item, index) => 
                  <ToolCard 
                      title={item.title}
                      description={item.description}
                      thumbnail={item.thumbnail}
                      pageUrl={item.pageUrl}
                      cta={item.cta}
                      index={index}
                      type={item.type}
                  />
              )}
            </div>
            <InFeedAd />
          </div>
        </section>
      </Layout>
    );
  }
}
